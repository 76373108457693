import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Top from './Top.js';
import Book from './Book.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={'/'} element={<Top />} />
        <Route exact path={'/book'} element={<Book />} />
      </Routes>
      <Link to='/'>Back To Top</Link>
    </BrowserRouter>
  );
}

export default App;
